<template>
  <div
    class="form-builder__input"
    :class="schema.styleClasses || ''"
  >
    <q-input
      :standout="!!schema.standout"
      class="full-width q-input--error-hint"
      :filled="!schema.noFilled"
      :outlined="schema.outlined"
      :color="schema.color || undefined"
      :type="isPwd ? 'password' : 'text'"
      :dark="$q.dark.isActive"
      :model-value="(schema.get && schema.get(schema.value)) || schema.value"
      :disable="!!schema.disabled"
      :required="!!schema.required"
      :label="`${schema.label} ${schema.required ? '*' : ''}`"
      :placeholder="schema.placeholder || ''"
      :hide-bottom-space="!schema.hint"
      :hint="schema.hint || undefined"
      :dense="!!schema.dense"
      :debounce="schema.debounce || 0"
      :autocomplete="'new-password'"
      :error-message="error"
      :error="!!error"
      @change="handleChange"
      @update:model-value="handleInput"
      @focus="handleFocus"
      @blur="handleFocusOut"
    >
      <template v-if="schema.icon" v-slot:prepend>
        <q-icon :name="schema.icon" :color="schema.iconColor" />
      </template>

      <template v-if="schema.details" v-slot:append>
        <q-icon
          :name="isPwd ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          :color="schema.iconColor"
          @click="isPwd = !isPwd"
        />

        <q-icon v-if="schema.details.length > 0" name="info" :color="schema.iconColor" />

        <q-tooltip v-if="schema.details.length > 0" v-model="isOpen" class="q-px-sm text-left">
          <ul class="q-ma-none q-pl-md">
            <li
              v-for="(item, i) in schema.details"
              :key="i"
              class="text-caption text-weight-light"
            >
              {{ item }}
            </li>
          </ul>
        </q-tooltip>
      </template>
    </q-input>
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      timeout: null,
      isPwd: true
    }
  },
  computed: {
    error () {
      return this.validate(this.schema.value) || this.schema.error
    }
  },
  mounted () {
    if (this.schema.autoShow) {
      this.isOpen = true

      this.timeout = setTimeout(() => {
        this.isOpen = false
        this.timeout = null
      }, this.schema.autoShow)
    }
  },
  unmounted () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    reset () {
      this.handleChange('')
      this.handleInput('')
    },
    validate (text) {
      if (this.schema.max && `${text}`.length > this.schema.max) {
        return `Please use maximum ${this.schema.max} characters`
      }

      return ''
    },
    handleChange (e) {
      if (this.schema.onChange) {
        const value = this.schema.inputType === 'number'
          ? Number(e)
          : e

        this.schema.onChange(value, this.schema.value)
      }
    },
    handleInput (e) {
      if (this.schema.onInput) {
        const value = this.schema.inputType === 'number'
          ? Number(e)
          : e

        this.schema.onInput(value, this.schema.value)
      }
    },
    handleFocus (e) {
      if (this.schema.onFocus) {
        this.schema.onFocus(e)
      }
    },
    handleFocusOut (e) {
      if (this.schema.onFocusOut) {
        this.schema.onFocusOut(e)
      }
    }
  }
}
</script>

<style>
.q-input.q-input--error-hint .q-field__messages {
  color: #C10015;
}
</style>